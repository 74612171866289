Vue.filter("salesPriceFormat", function (price, format) {
  if (!format) format = ":PRICE:";
  return format.replace(/:PRICE:/, price);
});

Vue.filter("taxFormat", function (price, format) {
  if (!format) format = ":PRICE:";
  const floor = function (v) {
    return Math.floor(v)
  };
  const ceil = function (v) {
    return Math.ceil(v)
  };
  const round = function (v) {
    return Math.round(v)
  };

  var formatted = format.replace(/:PRICE:/g, price);
  formatted = formatted.replace(/\${([^}]+)}/g, function (matched, formula) {
    var taxIncludedPrice;
    try {
      taxIncludedPrice = eval(formula);
    } catch (e) {
      taxIncludedPrice = "";
    }

    return taxIncludedPrice
  });

  return formatted
});

Vue.filter("numberFormat", function (val) {
  if(typeof val === "number") {
    val = val.toString();
  }
  return val.replace(/\d+/, function (matched) {
    return Intl.NumberFormat().format(matched)
  })
});
